import { WIDGET_EMPTY_VALUE, WIDGET_PROPERTY } from './types';

export default {
  WIDGET_PROPERTY,
  WIDGET_EMPTY_VALUE,
  methods: {
    setWidgetResetValue(widgetName) {
      this.setWidgetProperty(widgetName, WIDGET_PROPERTY.VALUE, WIDGET_EMPTY_VALUE);
    },

    /**
     * Update properties of widget
     *
     * @param widgetName
     * @param properties = { property: value }
     * @param params = { property: { param: value, ... } }
     */
    setWidgetProperties(widgetName, properties, params = {}) {
      Object.keys(properties).forEach((property) => {
        this.setWidgetProperty(
          widgetName,
          property,
          properties[property],
          params.hasOwnProperty(property) ? params[property] : {},
        );
      });
    },

    setWidgetProperty(widgetName, property, value, params = {}) {
      const widget = this.getWidget(widgetName);

      if (!widget) {
        return;
      }

      switch (property) {
        case WIDGET_PROPERTY.VALUE:
          if (!this.value.hasOwnProperty(widget.name)) {
            return false;
          }
          this.value[widget.name] = value;
          break;

        case WIDGET_PROPERTY.DISABLE:
          widget.disabled = value;
          break;

        case WIDGET_PROPERTY.VISIBLE:
          widget.hidden = !value;
          break;

        case WIDGET_PROPERTY.REQUIRED:
          widget.required = value;
          break;

        case WIDGET_PROPERTY.LABEL:
          widget.label = value;
          break;

        case WIDGET_PROPERTY.RULES:
          widget.rules = value;
          break;

        case WIDGET_PROPERTY.CLEARABLE:
          widget.clearable = value;
          break;

        case WIDGET_PROPERTY.OPTIONS:
          widget.options = value;
          widget.items = this.getItems(widget);
          break;

        case WIDGET_PROPERTY.RELATION:
          const relationItems = this.provider.relation(value);

          if (!relationItems) {
            return false;
          }

          widget.relation = value;

          const criteriaObj = widget.relationCriteria(this.value, widget, this.widgets);

          widget.items = relationItems.filter((item) => {
            for (let key of Object.keys(criteriaObj)) {
              if (item.hasOwnProperty(key) && item[key] !== criteriaObj[key]) {
                return false;
              }
            }

            return true;
          });

          if (params.relationId) {
            widget.relationId = params.relationId;
          }
          if (params.relationText) {
            widget.relationText = params.relationText;
          }
          break;

        case WIDGET_PROPERTY.MIN:
          widget.min = value;
          break;

        case WIDGET_PROPERTY.MAX:
          widget.max = value;
          break;

        case WIDGET_PROPERTY.INPUT_TYPE:
          widget.inputType = value;
          break;

        case WIDGET_PROPERTY.PREFIX:
          widget.prefix = value;
          break;

        case WIDGET_PROPERTY.SUFFIX:
          widget.suffix = value;
          break;

        case WIDGET_PROPERTY.SAVE:
          widget.save = value;
          break;

        case WIDGET_PROPERTY.ROW:
          widget.row = value;
          break;
      }

      return this.updateWidget(widget);
    },

    getWidget(name) {
      return this.widgets.find((widget) => widget.name === name) ?? null;
    },

    getWidgetValue(name) {
      if (!this.value.hasOwnProperty(name)) {
        return null;
      }
      return this.value[name];
    },

    updateWidget(updatedWidget) {
      if (!updatedWidget.name) {
        return false;
      }

      const index = this.widgets.findIndex((widget) => widget.name === updatedWidget.name);

      if (index === undefined) {
        return false;
      }

      this.widgets.splice(index, 1, updatedWidget);

      return true;
    },

    option_strToObject(option) {
      return {
        name: typeof option === 'object' && option.name ? option.name : option,
        id: typeof option === 'object' && option.id ? option.id : option,
        disabled: false,
      };
    },

    options_strToObject(widget) {
      if (!widget.options || !Array.isArray(widget.options)) {
        return [];
      }
      return widget.options.map(this.option_strToObject);
    },

    widgetDefaultOptions(widget) {
      switch (widget.type) {
        case this.$options.FORM_FIELD_TYPES.autocomplete:
        case this.$options.FORM_FIELD_TYPES.select:
          return this.options_strToObject(widget);
        case this.$options.FORM_FIELD_TYPES.relationAutocomplete:
        case this.$options.FORM_FIELD_TYPES.relationSelect:
          return this.provider.relation(widget.relation);
        default:
          return null;
      }
    },

    widgetOptions(widget) {
      switch (widget.type) {
        case this.$options.FORM_FIELD_TYPES.autocomplete:
        case this.$options.FORM_FIELD_TYPES.select:
          return this.options_strToObject(widget);
        case this.$options.FORM_FIELD_TYPES.relationAutocomplete:
        case this.$options.FORM_FIELD_TYPES.relationSelect:
          return widget.items;
        default:
          return null;
      }
    },

    getWidgetDefaultValue(widget) {
      if (widget.hasOwnProperty('default') && widget.default) {
        return widget.default;
      } else {
        switch (widget.type) {
          case this.$options.FORM_FIELD_TYPES.autocomplete:
          case this.$options.FORM_FIELD_TYPES.relationAutocomplete:
            return widget.multiple ? [] : WIDGET_EMPTY_VALUE;
          case this.$options.FORM_FIELD_TYPES.select:
          case this.$options.FORM_FIELD_TYPES.relationSelect:
            return widget.multiple ? [] : WIDGET_EMPTY_VALUE;
          case this.$options.FORM_FIELD_TYPES.upload:
          case this.$options.FORM_FIELD_TYPES.uploadImage:
            return widget.multiple ? [] : null;
          case this.$options.FORM_FIELD_TYPES.checkBox:
            return false;
          default:
            return widget.default !== undefined ? widget.default : '';
        }
      }
    },
  },
};
