<template>
  <div
    v-if="editor"
    class="html-editor"
  >
    <div class="label">{{ label }}</div>

    <div class="editor">
      <MenuBar :editor="editor" />
      <EditorContent
        class="editor-content"
        :editor="editor"
      />

      <div
        v-if="disabled"
        class="disabled-wrapper"
      ></div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';

import MenuBar from './MenuBar.vue';

export default {
  components: {
    EditorContent,
    MenuBar,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Link.configure({
          openOnClick: false,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
.html-editor {
  width: 100%;
}

.editor {
  position: relative;
  border: 1px solid #515151;
  border-radius: 8px;
  background-color: var(--color-grey-280);

  &__menubar {
    .theme--light.v-icon {
      color: var(--color-button-text--primary) !important;
    }
    .v-btn.v-btn--has-bg {
      background-color: transparent !important;
    }
  }
  &__content {
    background-color: var(--color-white);
  }
}

.label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: var(--color-grey-600);
  margin-bottom: 20px;
}

.editor-content {
  overflow-y: scroll;
  max-height: 630px;
  padding: 40px 30px;

  @media (max-width: 1200px) {
    padding: 20px 15px;
  }
}

.disabled-wrapper {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--color-grey-500);
  opacity: 0.5;
  border-radius: 5px;
  z-index: 100;
}
</style>

<style lang="scss">
.tiptap {
  outline: none;

  a {
    cursor: initial;
  }
}
</style>
