export default {
  data() {
    return {
      default_col_lg: 3,
      default_col_md: 3,
      default_col_sm: 6,
      default_col_cols: 12,
    };
  },
  methods: {
    widgetsForRow(row) {
      return this.widgets.filter((widget) => widget.row === row).filter((widget) => !widget.hidden);
    },

    widgetSm(widget) {
      if (widget.type === this.$options.FORM_FIELD_TYPES.html) {
        return widget.sm ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.upload && widget.multiple) {
        return widget.sm ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.uploadImage) {
        return widget.sm ?? 12;
      }

      return widget.sm ?? this.default_col_sm;
    },

    widgetMd(widget) {
      if (widget.type === this.$options.FORM_FIELD_TYPES.html) {
        return widget.md ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.upload && widget.multiple) {
        return widget.md ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.uploadImage) {
        return widget.md ?? 12;
      }

      return widget.md ?? this.default_col_md;
    },

    widgetLg(widget) {
      if (widget.type === this.$options.FORM_FIELD_TYPES.html) {
        return widget.lg ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.upload && widget.multiple) {
        return widget.lg ?? 12;
      }
      if (widget.type === this.$options.FORM_FIELD_TYPES.uploadImage) {
        return widget.lg ?? 12;
      }

      return widget.lg ?? this.default_col_lg;
    },

    marginBottom(widget) {
      switch (widget.type) {
        case this.$options.FORM_FIELD_TYPES.select:
          return ['mb-2'];
        case this.$options.FORM_FIELD_TYPES.relationSelect:
          return ['mb-2'];
        case this.$options.FORM_FIELD_TYPES.autocomplete:
          return ['mb-2'];
        case this.$options.FORM_FIELD_TYPES.relationAutocomplete:
          return ['mb-2'];
        case this.$options.FORM_FIELD_TYPES.date:
          return ['mb-2'];
        case this.$options.FORM_FIELD_TYPES.uploadImage:
          return ['mt-2', 'mb-6'];
        default:
          return ['mb-0'];
      }
    },
  },
};
