export const alert = {
  namespaced: true,

  state: {
    type: '',
    message: '',
  },

  mutations: {
    setAlert(state, options) {
      state.type = options.type;
      state.message = options.message;
    },
  },

  actions: {
    setAlert({ commit }, options) {
      if (options.message.code === 1) {
        return;
      }

      commit('setAlert', options);
    },
  },
};
