import store from '@/store';

export function checkTokenExpiration() {
  try {
    let payload = localStorage.getItem('token').split('.')[1];
    let payloadDecoded = JSON.parse(window.atob(payload));

    return Math.floor(Date.now() / 1000) >= payloadDecoded.exp ? false : true;
  } catch (err) {
    return false;
  }
}

export async function getFileForUrl(url, fileName) {
  const res = await fetch(url);
  const blob = await res.blob();
  return new File([blob], fileName);
}

export function getImageUrl(img) {
  if (!img) {
    return '';
  }
  return img.uuid ? img.url : URL.createObjectURL(img);
}

export function hasPermission(permission) {
  return store.getters['user/hasPermission'](permission);
}

export function isClient() {
  return store.getters['user/isClient'];
}

export function slugify(string) {
  string = string.toLowerCase(); // convert string to lowercase

  string = string
    .replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-') // remove consecutive hyphens
    .replace(/^-+|-+$/g, ''); // remove all start and end hyphens

  return string;
}
