<template>
  <div
    class="edit-form"
    data-testid="elem_u_000090"
  >
    <v-form
      @submit.prevent
      class="form-edit-dialog__form"
      ref="form"
      data-testid="elem_u_000667"
      autocomplete="off"
    >
      <v-container
        class="ma-0 pa-0"
        fluid
        data-testid="elem_u_003160"
      >
        <v-row
          v-if="$scopedSlots.before"
          class="edit-form__row ma-0"
          data-testid="elem_u_000668"
        >
          <slot
            name="before"
            :item="value"
          />
        </v-row>

        <v-row
          v-for="(row, i) in rows"
          :key="i"
          class="edit-form__row ma-0"
          data-testid="elem_u_000670"
        >
          <v-col
            v-if="$scopedSlots[`row${row}`]"
            :sm="12"
            :md="12"
            :lg="12"
            :cols="12"
            data-testid="elem_u_000671"
          >
            <slot :name="`row${row}`" />
          </v-col>

          <template v-if="!$scopedSlots[`row${row}`]">
            <v-col
              v-for="widget in widgetsForRow(row)"
              :key="widget.id"
              :sm="widgetSm(widget)"
              :md="widgetMd(widget)"
              :lg="widgetLg(widget)"
              :offsetSm="widget.offsetSm"
              :offsetMd="widget.offsetMd"
              :offsetLg="widget.offsetLg"
              :cols="default_col_cols"
              class="py-0 edit-form__col"
              :class="[marginBottom(widget)]"
              data-testid="elem_u_000672"
            >
              <!-- Select -->

              <UnzipSelect
                v-if="widget.type === $options.FORM_FIELD_TYPES.select"
                @change="(val) => onWidgetInput(widget.name, val)"
                v-model="value[widget.name]"
                :label="widget.label"
                :items="widgetOptions(widget)"
                :loading="provider.loading.relations"
                :clearable="widget.clearable"
                :required="widget.required"
                :disabled="widget.disabled"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                item-text="name"
                item-value="id"
                class="unzipvr-input"
              />

              <!-- Relation Select -->
              <UnzipSelect
                v-if="widget.type === $options.FORM_FIELD_TYPES.relationSelect"
                v-model="value[widget.name]"
                :label="widget.label"
                :items="widgetOptions(widget)"
                :item-text="widget.relationText"
                :item-value="widget.relationId ? widget.relationId : widget.relationText"
                :loading="provider.loading.relations"
                :required="widget.required"
                :rules="widget.rules"
                :disabled="widget.disabled"
                :clearable="widget.clearable"
                :multiple="widget.multiple"
                @focus="onFocus(widget.name)"
                @input="(val) => onWidgetInput(widget.name, val)"
                data-testid="elem_u_000674"
                class="unzipvr-input"
              />

              <!-- Autocomplete -->
              <SelectInput
                v-if="widget.type === $options.FORM_FIELD_TYPES.autocomplete"
                data-testid="elem_u_000675"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :items="widgetOptions(widget)"
                item-id="id"
                item-text="name"
                :clearable="widget.clearable"
                :label="widget.label"
                :multiple="widget.multiple"
                :required="widget.required"
                :disabled="widget.disabled"
                :other-id="widget.otherId"
                @focus="onFocus(widget.name)"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                autocomplete
                :other-value.sync="value[widget.otherFieldForSave]"
              />

              <!-- Relation Autofilter -->
              <UnzipSelect
                v-if="widget.type === $options.FORM_FIELD_TYPES.relationAutocomplete"
                v-model="value[widget.name]"
                :label="widget.label"
                :items="widgetOptions(widget)"
                :item-text="widget.relationText"
                :item-value="widget.relationId ? widget.relationId : widget.relationText"
                :clearable="widget.clearable"
                :loading="provider.loading.relations"
                :required="widget.required"
                :disabled="widget.disabled"
                :showExactSearch="widget.exactSearch"
                :rules="widget.rules"
                @input="(val) => onWidgetInput(widget.name, val)"
                @focus="onFocus(widget.name)"
                autocomplete
                data-testid="elem_u_000676"
                class="unzipvr-input"
              />

              <!-- DataPicker -->
              <DatePicker
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.date"
                data-testid="elem_u_000677"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :label="widget.label"
                :class="{ required: widget.required }"
                :min="widget.min"
                :max="widget.max"
                :disabled="widget.disabled"
                @focus="onFocus(widget.name)"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                validate-on-blur
              />

              <!-- Input -->

              <div v-else-if="widget.type === $options.FORM_FIELD_TYPES.input">
                <v-text-field
                  data-testid="elem_u_000678"
                  v-model.trim="value[widget.name]"
                  :label="widget.label"
                  @input="(val) => onWidgetInput(widget.name, val)"
                  :type="widget.inputType"
                  class="unzipvr-input"
                  :class="{ required: widget.required }"
                  :prefix="widget.prefix"
                  :suffix="widget.suffix"
                  :maxlength="widget.max"
                  :disabled="widget.disabled"
                  @focus="onFocus(widget.name)"
                  @blur="onBlur(widget.name)"
                  :rules="widget.rules"
                  :error-messages="errors[widget.name]"
                  validate-on-blur
                  :required="widget.required"
                  :autocomplete="widget.autocomplete"
                />

                <v-tooltip
                  :nudge-top="30"
                  bottom
                  offset-overflow
                  max-width="300"
                  :open-delay="300"
                  z-index="100"
                  v-model="tooltip"
                  v-if="widget.tooltip && tooltipName === widget.name"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      icon
                      v-bind="attrs"
                      v-on="on"
                    ></div>
                  </template>

                  <div v-html="widget.tooltip"></div>
                </v-tooltip>
              </div>

              <!-- Textarea -->
              <v-textarea
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.textarea"
                v-model.trim="value[widget.name]"
                :label="widget.label"
                @input="(val) => onWidgetInput(widget.name, val)"
                class="unzipvr-input"
                :class="{ required: widget.required }"
                :prefix="widget.prefix"
                :suffix="widget.suffix"
                :maxlength="widget.max"
                :disabled="widget.disabled"
                :rows="widget.rowsCount || 1"
                @focus="onFocus(widget.name)"
                :required="widget.required"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                :outlined="widget.outlined === false ? false : true"
                validate-on-blur
                direction="vertical"
                auto-grow
                data-testid="elem_u_000679"
                placeholder="Leave a note here."
              />

              <!-- Checkbox  -->
              <v-checkbox
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.checkBox"
                data-testid="elem_u_000680"
                v-model.trim="value[widget.name]"
                @change="(val) => onWidgetInput(widget.name, val)"
                :label="widget.label"
                color="#F4DB64"
                hide-details
                class="unzipvr-input"
                :class="{ required: widget.required }"
                :disabled="widget.disabled"
                @focus="onFocus(widget.name)"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                validate-on-blur
                :ripple="false"
              />

              <!-- Password -->
              <PasswordInput
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.password"
                data-testid="elem_u_000681"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :label="widget.label"
                :class="{ required: widget.required }"
                @focus="onFocus(widget.name)"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                validate-on-blur
                :disabled="widget.disabled"
                :password-repeat="widget.passwordRepeat"
                :password-repeat-label="widget.passwordRepeatLabel"
                :password-repeat-appearence="widget.passwordRepeatAppearence"
                :autocomplete="widget.autocomplete"
              />

              <!-- HTML Editor -->
              <HtmlEditor
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.html"
                @input="(val) => onWidgetInput(widget.name, val)"
                v-model.trim="value[widget.name]"
                :label="widget.label"
                :required="widget.required"
                :rules="widget.rules"
                :disabled="widget.disabled"
                data-testid="elem_u_000682"
              />

              <!-- Single Upload Button -->
              <UploadBlock
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.upload && !widget.multiple"
                data-testid="elem_u_000683"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :required="widget.required"
                :accept="widget.accept"
                :hint="widget.hint"
                :hintBottom="widget.hintBottom"
                :clearable="widget.clearable"
                :default-label="uploadLabel"
                @focus="onFocus(widget.name)"
                :rules="widget.rules"
                :error-messages="errors[widget.name]"
                validate-on-blur
              />

              <!-- Multi Upload Button -->
              <MultiUploadBlock
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.upload && widget.multiple"
                data-testid="elem_u_000684"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :title="widget.label"
                :label="widget.label"
                :accept="widget.accept"
                @drop="(image) => deleteImage(widget, image)"
                validate-on-blur
              />

              <!--  Single Upload Image -->
              <UploadImage
                v-else-if="
                  widget.type === $options.FORM_FIELD_TYPES.uploadImage && !widget.multiple
                "
                data-testid="elem_u_000685"
                class="upload-image-wrapper"
                v-model="value[widget.name]"
                @input="(val) => onWidgetInput(widget.name, val)"
                :required="widget.required"
                :rounded="widget.rounded"
                :rectangular="widget.rectangular"
                :formats="widget.formats"
                :hint="widget.hint"
                :hintBottom="widget.hintBottom"
                :clearable="widget.clearable"
                :label="widget.label"
                :size="widget.size"
                :showProgress="widget.showProgress"
                :progress="provider.progress"
                :error-messages="errors[widget.name]"
              />

              <!-- Multi Upload Button -->
              <UploadImage
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.uploadImage && widget.multiple"
                data-testid="elem_u_000686"
                v-model="value[widget.name]"
                :label="widget.label"
                :required="widget.required"
                :rounded="widget.rounded"
                :formats="widget.formats"
                :clearable="widget.clearable"
                multiple
                @input="(val) => onWidgetInput(widget.name, val)"
                @drop="(image) => deleteImage(widget, image)"
                :error-messages="errors[widget.name]"
              />

              <!-- Color -->
              <div
                v-else-if="widget.type === $options.FORM_FIELD_TYPES.color"
                :required="widget.required"
                :error-messages="errors[widget.name]"
                data-testid="elem_u_002547"
              >
                <div
                  class="widget-label"
                  data-testid="elem_u_002548"
                >
                  {{ widget.label }}
                </div>
                <div
                  class="select-color"
                  data-testid="elem_u_002549"
                >
                  <div
                    v-for="color in $options.COLORS"
                    :key="color"
                    data-testid="elem_u_002550"
                  >
                    <input
                      type="radio"
                      name="color"
                      :id="color"
                      :value="color"
                      @change="(val) => onWidgetInput(widget.name, val.target.value)"
                      v-model="value[widget.name]"
                      data-testid="elem_u_002551"
                    />
                    <label
                      :for="color"
                      :style="`background-color: ${color};`"
                      data-testid="elem_u_002552"
                    ></label>
                  </div>
                </div>
              </div>
            </v-col>
          </template>

          <v-col
            v-if="buttonsVisible(row)"
            cols="12"
            sm="6"
            md="3"
            lg="2"
            class="pt-5 ml-auto"
            data-testid="elem_u_000687"
          >
            <UnzipButton
              v-if="saveButton"
              @click="save"
              :loading="saveLoading"
              data-testid="elem_u_000688"
            >
              <span data-testid="elem_u_000091">{{ saveButtonLabel }}</span>
            </UnzipButton>
            <UnzipButton
              v-if="clearButton"
              @click="clear"
              type="secondary"
              outlined
              data-testid="elem_u_000689"
            >
              <span data-testid="elem_u_000092">{{ clearButtonLabel }}</span>
            </UnzipButton>
          </v-col>
        </v-row>

        <v-row
          v-if="$scopedSlots.after"
          class="edit-form__row ma-0"
          data-testid="elem_u_000690"
        >
          <slot
            name="after"
            :item="value"
          />
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { FORM_FIELD_TYPES, WIDGET_EMPTY_VALUE } from './types';
import { LOAD_TYPES } from '@/services/DataProvider/subscribes-on-load';
import { checkRules } from './validates';
//Mixins
import widgetApi from './widget-api';
import formApi from './form-api';
import gridhelpers from './grid-helpers';

// Components
import DatePicker from '@/components/common/DatePicker';
import HtmlEditor from '@/components/common/HtmlEditor/HtmlEditor.vue';
import UploadBlock from '@/components/common/Uploads/UploadBlock';
import MultiUploadBlock from '@/components/common/Uploads/MultiUploadBlock';
import UploadImage from '@/components/common/Uploads/UploadImage';
import PasswordInput from '@/components/common/PasswordInput';
import SelectInput from '@/components/common/SelectInput';

/**
 * Messages
 *  form_edit_block: {
 *    button_update,
 *    button_create,
 *    button_clear
 *  },
 *  alert: {
 *    created_successfully,
 *    updated_successfully
 *  }
 *
 */

const COLORS = [
  '#FF6262',
  '#FFEE9E',
  '#8CD317',
  '#FFFFFF',
  '#6297FF',
  '#A762FF',
  '#FF62DC',
  '#62ECFF',
];

export default {
  FORM_FIELD_TYPES,
  LOAD_TYPES,
  COLORS,

  event: [
    'save', // => true/false
    'on-input-widget', // => {name, value}
  ],

  mixins: [widgetApi, formApi, gridhelpers],

  components: {
    DatePicker,
    HtmlEditor,
    UploadBlock,
    MultiUploadBlock,
    PasswordInput,
    SelectInput,
    UploadImage,
    UnzipSelect: () => import('@/components/common/UnzipSelect'),
  },

  props: {
    provider: {
      type: Object,
      required: true,
    },
    messages: {
      type: String,
      default: '',
    },
    saveButton: {
      type: Boolean,
      default: false,
    },
    clearButton: {
      type: Boolean,
      default: false,
    },

    /**
     *  {
     *    name,
     *    type,
     *    default,
     *    label,
     *    rules ([(value) => boolean]),
     *    options (string or {id, name}),
     *    relation,
     *    relationText,
     *    relationId,
     *    relationCriteria ((value, widget, widgets) => { field1: value, field2: value, ... }, выбор значений из списка с полями равными указанными значениями),
     *    otherField,
     *    row,
     *    multiple,
     *    password,
     *    lg,
     *    md,
     *    sm,
     *    offsetLg,
     *    offsetMd,
     *    offsetSm,
     *    required,
     *    disabled,
     *    inputType,
     *    prefix,
     *    suffix,
     *    min,
     *    max,
     *    accept,
     *    hintBottom,
     *    clearable
     *  }
     */
    fields: {
      type: Array,
      default() {
        return [];
      },
    },

    noResetAfterSave: {
      type: Boolean,
      default: false,
    },
    noSaveMessage: {
      type: Boolean,
      default: false,
    },
    noServerSave: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: {},
      isCreated: true,
      deleted_images: {},
      options: {},
      errors: {},
      tooltip: false,
      tooltipName: '',
    };
  },

  computed: {
    widgets() {
      return this.fields.map((field) => ({
        ...field,
        id: field.id ?? null,
        type: field.type ?? FORM_FIELD_TYPES.input,
        items: this.widgetDefaultOptions(field),
        default: this.getWidgetDefaultValue(field),
        value: '',
        required: field.required ?? false,
        rules: this.widgetRules(field.label, field.rules ?? [], field.required ?? false),
        menu: false,
        row: field.row ?? 1,
        prefix: field.prefix ?? '',
        suffix: field.suffix ?? '',
        min: field.min ?? '',
        max: field.max ?? '',
        accept: field.accept ?? 'image/*',
        relationId: field.relationId ?? 'id',
        relationText: field.relationText ?? 'name',
        relationCriteria: field.relationCriteria ?? null,
        disabled: field.disabled ?? false,
        otherId: field.otherId ?? '',
        hidden: field.hidden ?? false,
        save: field.save ?? true,
        rounded: field.rounded ?? false,
        rectangular: field.rectangular ?? false,
        formats: field.formats ?? [],
        clearable: field.clearable ?? false,
        color: field.color ?? '#FFFFFF',
        autocomplete: field.autocomplete ?? 'off',
      }));
    },
    rows() {
      if (!this.widgets) {
        return [];
      }
      const maxRow = this.widgets.reduce(
        (maxRow, widget) => (maxRow < widget.row ? widget.row : maxRow),
        1,
      );
      return Array.from({ length: maxRow }, (_, i) => ++i);
    },
    saveButtonLabel() {
      return this.isCreated
        ? this.messages
          ? this.$t(`${this.messages}.form_edit_block.button_update`)
          : 'Update'
        : this.messages
          ? this.$t(`${this.messages}.form_edit_block.button_update`)
          : 'Save';
    },
    clearButtonLabel() {
      return this.messages ? this.$t(`${this.messages}.form_edit_block.button_clear`) : 'Clear';
    },
    uploadLabel() {
      return this.messages
        ? this.$t(`${this.messages}.form_edit_block.button_upload`)
        : 'Upload Image';
    },
    saveLoading() {
      return this.provider.loading.create || this.provider.loading.update;
    },
  },

  methods: {
    onWidgetInput(name, value) {
      if (value && value !== WIDGET_EMPTY_VALUE) {
        const widget = this.getWidget(name);
        if (this.isCustomWidget(widget)) {
          this.validateCustomWidget(widget);
        }
      }

      this.$emit('on-input-widget', { name, value });
    },

    widgetRules(label, rules, required) {
      return required
        ? [
            ...rules,
            (value) =>
              value === 0 ||
              (Boolean(value) &&
                value !== WIDGET_EMPTY_VALUE &&
                (Array.isArray(value) ? value.length > 0 : true)) ||
              `This field '${label}' is required.`,
          ]
        : rules;
    },

    isCustomWidget(widget) {
      switch (widget.type) {
        case FORM_FIELD_TYPES.uploadImage:
        case FORM_FIELD_TYPES.html:
        case FORM_FIELD_TYPES.select:
        case FORM_FIELD_TYPES.relationSelect:
        case FORM_FIELD_TYPES.autocomplete:
        case FORM_FIELD_TYPES.relationAutocomplete:
        case FORM_FIELD_TYPES.date:
        case FORM_FIELD_TYPES.password:
        case FORM_FIELD_TYPES.color:
          return true;
        default:
          return false;
      }
    },

    validateCustomWidgets() {
      return this.widgets.reduce((isValidate, widget) => {
        return this.isCustomWidget(widget) && !this.validateCustomWidget(widget) && isValidate
          ? false
          : isValidate;
      }, true);
    },

    validateCustomWidget(widget) {
      if (!widget.required) return true;

      this.errors[widget.name] = checkRules(widget.rules, this.value[widget.name]);

      return this.errors[widget.name].length === 0;
    },

    validate() {
      const validateWidgets = this.$refs.form.validate();
      const validateCustomWidgets = this.validateCustomWidgets();

      return validateWidgets && validateCustomWidgets;
    },

    onFocus(name) {
      this.errors[name] = [];
      this.tooltip = true;
      this.tooltipName = name;
    },

    onBlur() {
      this.tooltip = false;
      this.tooltipName = '';
    },

    resetAllErrors() {
      this.widgets.forEach((widget) => this.onFocus(widget.name));
      this.$refs.form.resetValidation();
    },

    getRelationItems(widget) {
      const relationItems = this.provider.relation(widget.relation);

      if (!widget.relationCriteria) {
        return relationItems;
      }

      const criteriaObj = widget.relationCriteria(this.value, widget, this.widgets);

      return relationItems.filter((item) => {
        for (let key of Object.keys(criteriaObj)) {
          if (item.hasOwnProperty(key) && item[key] !== criteriaObj[key]) {
            return false;
          }
        }
        return true;
      });
    },

    deleteImage(widget, image) {
      if (!image || !image.uuid) {
        return;
      }

      const deleted_name = `${widget.name}_deleted`;

      if (!this.deleted_images[deleted_name]) {
        this.deleted_images[deleted_name] = [];
      }

      const exists = this.deleted_images[deleted_name].some(
        (deleted_image_uuid) => deleted_image_uuid === image.uuid,
      );

      if (!exists) {
        this.deleted_images[deleted_name].push(image.uuid);
      }
    },

    buttonsVisible(row) {
      const lastRow = this.rows.slice(-1)[0];
      return row === lastRow && (this.saveButton || this.clearButton);
    },

    setRelations(type, state) {
      if (type === LOAD_TYPES.RELATIONS && !state) {
        this.widgets.forEach((widget) => {
          switch (widget.type) {
            case FORM_FIELD_TYPES.relationAutocomplete:
            case FORM_FIELD_TYPES.relationSelect:
              widget.items = this.getRelationItems(widget);
              this.updateWidget(widget);
              break;
          }
        });
      }
    },
  },

  mounted() {
    this.resetValue();

    // Если в провайдер получается единичный элемент (напр, профиль), то выставляем его
    if (!Array.isArray(this.provider.data)) {
      this.setValue(this.provider.data, { isCreate: false });
    }

    this.$nextTick(() => {
      this.$emit('mounted', this.value);
    });
  },

  async created() {
    this.errors = this.fields.reduce((obj, field) => ({ ...obj, [field.name]: [] }), {});

    this.provider.subscribeOnLoad(LOAD_TYPES.RELATIONS, (type, state) =>
      this.setRelations(type, state),
    );
  },
};
</script>

<style lang="scss">
.edit-form__row {
  min-height: 67px;
  .edit-form__col {
    min-height: 77px;
    height: fit-content;
    padding: 0 10px;
    margin: 0 !important;
  }
}

.theme--dark.v-text-field.v-input--is-disabled .v-input__slot::before {
  border-image: none !important;
}

.theme--dark.v-text-field.v-input--is-disabled {
  &.unzipvr-input.v-input .v-input__slot:before,
  &.unzipvr-input.v-input .v-input__slot:after {
    border-color: var(--color-grey-400) !important;
  }
}

.v-select__selection--disabled {
  color: var(--color-grey-400) !important;
}
</style>

<style lang="scss" scoped>
.widget-label {
  font-size: 11.25px;
  line-height: 18px;
  color: rgba(233, 233, 233, 0.5);
}

.select-color {
  display: flex;
  column-gap: 10px;
  margin: 10px 0;

  & input[type='radio'] {
    display: none;
  }

  & label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46.47px;
    height: 46.47px;
    border-radius: 50%;
    cursor: pointer;
  }

  & label::before {
    content: '';
    width: 0px;
    height: 0px;
    background-color: black;
    border-radius: 50%;
    transition: all 0.25s;
  }

  & input[type='radio']:checked + label::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
  }
}

.upload-image-wrapper {
  margin-bottom: 10px;
}
</style>
