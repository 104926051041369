<template>
  <div
    class="select-input"
    data-testid="elem_u_000037"
  >
    <div
      v-if="val === otherId"
      data-testid="elem_u_000038"
    >
      <v-text-field
        :value="otherValue"
        @input="(val) => $emit(`update:otherValue`, val)"
        append-icon="menu"
        @click:append="switchToSelect"
        class="unzipvr-input"
        :class="{ required }"
        :label="label"
        placeholder="Other"
        ref="inputField"
        data-testid="elem_u_000868"
      />
    </div>

    <div
      v-else
      data-testid="elem_u_000039"
    >
      <v-select
        v-if="!autocomplete"
        v-model="val"
        :items="items"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        item-color="#F4DB64"
        color="#F4DB64"
        :clearable="clearable"
        :label="label"
        placeholder=""
        hide-details
        :multiple="multiple"
        class="unzipvr-input"
        :class="{ required }"
        :disabled="disabled"
        @focus="$emit('focus')"
        ref="select"
        data-testid="elem_u_000869"
      />

      <v-autocomplete
        v-else-if="autocomplete"
        v-model="val"
        :items="items"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        item-color="#F4DB64"
        color="#F4DB64"
        :label="label"
        placeholder=""
        :clearable="clearable"
        class="unzipvr-input"
        :class="{ required }"
        :disabled="disabled"
        hide-details="true"
        @focus="$emit('focus')"
        ref="autocomplete"
        data-testid="elem_u_000870"
      />
    </div>

    <form-error-line
      :message="errorMessages[0]"
      data-testid="elem_u_000871"
    />
  </div>
</template>

<script>
import { WIDGET_EMPTY_VALUE } from './Provider/EditFormBlock';

import FormErrorLine from '@/components/common/Provider/EditFormBlock/FormErrorLine';

export default {
  WIDGET_EMPTY_VALUE,
  events: ['focus'],
  components: {
    FormErrorLine,
  },
  props: {
    value: {
      type: String | Number,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: {
      type: String | Array,
      default() {
        return [];
      },
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    relation: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    otherId: {
      type: String,
      default: '',
    },
    otherFieldForSave: {
      type: String,
      default: '',
    },
    otherValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: null,
    };
  },
  computed: {
    itemValue() {
      return this.itemId ?? this.itemText;
    },
  },
  methods: {
    switchToSelect() {
      this.val = '';
    },
  },
  watch: {
    val: function (val) {
      this.$emit('input', val);
      if (val === this.otherId) {
        // this.$nextTick(() => {
        //   this.$refs.inputField.focus();
        // });
      } else {
        this.$emit(`update:otherValue`, undefined);
      }
    },
    value: function (val) {
      if (this.val === this.otherId) {
        this.$emit(`update:otherValue`, '');
      }

      if (val === WIDGET_EMPTY_VALUE) {
        this.val = null;
        if (this.autocomplete) {
          this.$refs.autocomplete.form.reset();
        } else {
          this.$refs.select.form.reset();
        }
      } else {
        this.val = val;
      }
    },
  },
};
</script>
